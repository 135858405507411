import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './EntryPage.css';
import TransitionAnimation from './TransitionAnimation';
import MainPage from './MainPage';

const EntryPage = () => {
  const navigate = useNavigate();
  const [showTransition, setShowTransition] = useState(false);

  const handleEnter = () => {
    setShowTransition(true);
  };
  
  const handleAnimationComplete = () => {
    navigate('/main', { replace: true });
  };

  if (showTransition) {
    return (
      <TransitionAnimation onAnimationComplete={handleAnimationComplete}>
        <MainPage />
      </TransitionAnimation>
    );
  }

  return (
    <div className="entry-page">
      <img
        src={`${process.env.PUBLIC_URL}/fwappy_logo-t.png`}
        alt="Tribute Logo"
        className="tribute-logo"
      />
      <img
        src={`${process.env.PUBLIC_URL}/Cute.png`}
        alt="Cute"
        className="bottom-left-image"
      />
      <button className="enter-button" onClick={handleEnter}>
        Enter
      </button>
    </div>
  );
};

export default EntryPage;